var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"content-layout"},[_c('div',{staticClass:"setting-layout"},[_c('div',{staticClass:"timeout-layout"},[_c('div',{staticClass:"setting-button",on:{"click":function($event){return _vm.allShow('time')}}},[(_vm.tab === '计时停止播放' && _vm.timestr && _vm.setType !== 'time')?_c('span',[_vm._v(_vm._s(_vm.timestr))]):_c('span',[_vm._v("计时器")])])]),_c('div',{staticClass:"reverberation-layout",on:{"click":function($event){return _vm.allShow('mixes')}}},[_c('div',{staticClass:"setting-button"},[_vm._v("组合")])]),_c('div',{staticClass:"share-layout",on:{"click":function($event){return _vm.allShow('share')}}},[_c('div',{staticClass:"setting-button"},[_vm._v("分享")])])]),_c('div',{class:[
      'setting-main',
      _vm.setType === 'time' ? 'settingTimeout' : '',
      _vm.setType === 'mixes' ? 'settingMixes' : '',
      _vm.setType === 'share' ? 'settingShare' : '',
    ]},[_c('SetTimeout',{directives:[{name:"show",rawName:"v-show",value:(_vm.setType === 'time'),expression:"setType === 'time'"}],on:{"close":_vm.allClose,"change":_vm.timeNumChange}}),_c('SetMixes',{directives:[{name:"show",rawName:"v-show",value:(_vm.setType === 'mixes'),expression:"setType === 'mixes'"}],attrs:{"list":_vm.mixesList},on:{"mixesClose":_vm.allClose,"saveMixes":_vm.saveMixes,"mixesPlay":_vm.mixesPlay,"randomMixes":_vm.setRandomVolumes,"mixesShare":_vm.mixesShare}}),_c('SetShare',{directives:[{name:"show",rawName:"v-show",value:(_vm.setType === 'share'),expression:"setType === 'share'"}],attrs:{"currentSound":_vm.currentSound}})],1),_c('div',{staticClass:"play-layout"},[_c('div',{staticClass:"play-button",class:{ stop: _vm.allPlay, play: !_vm.allPlay },on:{"click":_vm.allPlayChange}},[_c('div',{staticClass:"isplay"})]),_vm._m(0)]),_c('div',{staticClass:"music-list-layout"},[_c('ul',{staticClass:"music-list"},_vm._l((_vm.musicList),function(i,x){return _c('li',{key:x,staticClass:"music-item",on:{"click":function($event){return _vm.volumeAdd(i)}}},[_c('img',{style:(`background-color: rgba(200,200,200, ${i.volume / 100})`),attrs:{"src":i.icon,"alt":""}}),_c('span',[_vm._v(_vm._s(i.name))]),_c('div',{staticClass:"music-slider",on:{"click":function($event){$event.stopPropagation();}}},[_c('WhiteSlider',{attrs:{"value":i.volume},on:{"input":function($event){return _vm.inputChange(i, $event)}}})],1),_c('audio',{ref:i.name,refInFor:true,staticClass:"music-audio",attrs:{"loop":"","src":i.audio}})])}),0),_vm._m(1)])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"play-info-layout"},[_c('div',{staticClass:"play-name"},[_vm._v("轻轻的呜咽")]),_c('div',{staticClass:"play-tip"},[_vm._v("周围的声音可以冲走注意力")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"music-more"},[_c('div',{staticClass:"more-button"},[_vm._v("更多声音")])])
}]

export { render, staticRenderFns }