import Vue from 'vue';
import Vuex from 'vuex';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    volume: 100,
    loginShow: false,
    userInfo: {
      id: ''
    }
  },
  getters: {},
  mutations: {
    setVolume(state, volume) {
      state.volume = volume;
    },
    setLoginShow (state, value) {
      state.loginShow = value
    },
    setUserInfo (state, value) {
      if (value.user_id) {
        value.id = value.user_id
      }

      state.userInfo = value
      window.localStorage.setItem('userInfo', JSON.stringify(value))
    }
  },
  actions: {},
  modules: {}
});
