<template>
  <div class="setting-layout-main">
    <div class="setting-title mixesSave">
      <input type="text" v-model="saveName" placeholder="新组合名称" />
      <button @click="save">保存</button>
    </div>
    <div class="setting-center mixesList-layout">
      <div class="mixes-tips" v-if="!mixesList.length">还没有组合</div>
      <div class="mixesList" v-else>
        <div class="mixes-item" v-for="(item, index) in mixesList" :key="index">
          <div class="mixes-item-content" v-if="delNum !== index">
            <button class="mixes-item-name" @click="mixesPlay(item)">{{ item.name }}</button>
            <button class="mixes-item-share" @click="share(item, index)">分享</button>
            <button class="mixes-item-delete" @click="deleteItemShow(index)">
              ✕
            </button>
          </div>
          <div class="mixes-item-operate" v-else>
            <button class="mixes-item-cancel" @click="delNum = -1">取消</button>
            <button class="mixes-item-confirm" @click="deleteItem(index)">
              删除
            </button>
          </div>
        </div>
      </div>
    </div>
    <footer class="setting-footer">
      <button @click="closeEl">关闭</button>
      <button @click="randomMix">随机组合</button>
    </footer>
  </div>
</template>

<script>
export default {
  name: 'SetMixes',
  props: {
    musicSetting: {
      type: Object,
      default: () => ({}),
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      saveName: '',
      mixesList: [],
      delNum: -1
    }
  },
  watch: {
    list: {
      handler(val) {
        this.mixesList = val
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    share(item) {
      this.$emit('mixesShare', item)
    },
    randomMix() {
      this.$emit('randomMixes')
    },
    mixesPlay(item) {
      this.$emit('mixesPlay', item)
    },
    save() {
      this.$emit('saveMixes', this.saveName)
    },
    deleteItemShow(x) {
      this.delNum = x
    },
    deleteItem(index) {
      this.mixesList.splice(index, 1)
      this.delNum = -1
    },
    closeEl() {
      this.$emit('mixesClose')
    }
  }
}
</script>

<style lang='less' scoped>
.setting-layout-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  position: relative;
  input {
    font-size: 0.16rem;
    line-height: inherit;
    color: #fff;
    text-align: right;
    background-color: transparent;
    border: 1px solid rgba(255, 255, 255, 0.7);
    border-radius: 0.04rem;
    width: 0.54rem;
    margin: 0 0.08rem;
    padding: 0.04rem;
  }
  button {
    color: #fff;
    border: 0.02rem solid #fff;
    border-radius: 0.04rem;
    padding: 0.08rem 0.16rem;
    transition: color 0.3s, background-color 0.3s;
    cursor: pointer;
    &:hover {
      color: #1f1e21;
      background-color: #fff;
    }
  }
  .setting-title {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.08rem;
    height: 0.95rem;
    text-align: center;
  }
  .mixesSave {
    padding: 0 0.16rem;
    > input {
      color: #fff;
      border: 0.01rem solid rgba(255, 255, 255, 0.1);
      flex: 1;
      margin-right: 0.16rem;
      padding: 0.08rem;
      font-family: inherit;
      font-size: 0.2rem;
      text-align: left;
      &::placeholder {
        color: #b2b1b0;
      }
    }
  }
  .setting-center {
    width: 100%;
    height: 100%;
  }
  .mixesList-layout {
    background-color: rgba(255, 255, 255, 0.1);
    border-top: 0.01rem solid rgba(255, 255, 255, 0.1);
    border-bottom: 0.01rem solid rgba(255, 255, 255, 0.1);
    flex-direction: column;
    flex: 1;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0.08rem 0.16rem;
    display: flex;
    overflow-y: auto;
  }
  .mixes-tips {
    width: 100%;
    height: 100%;
    display: flex;
    align-content: center;
    justify-content: center;
    flex-wrap: wrap;
  }

  .mixesList {
    width: 100%;
    height: 100%;
    padding: 0.04rem 0;
    .mixes-item {
      width: 100%;
      min-height: 0.48rem;
      margin-bottom: 0.12rem;
      display: flex;
      position: relative;
      button {
        // background-color: rgba(0, 0, 0, 0.3);
        border-top: 0.02rem solid #fff;
        border-bottom: 0.02rem solid #fff;
        padding: 0.08rem;
        transition: background-color 0.3s, color 0.3s;
      }
      .mixes-item-content {
        flex: 1;
        width: 100%;
        height: 0.48rem;
        display: flex;
        position: relative;

        .mixes-item-name,
        .mixes-item-delete {
          border-left: 0.02rem solid #fff;
          border-right: 0.02rem solid #fff;
          border-radius: 0.04rem 0 0 0.04rem;
        }
        .mixes-item-name {
          flex: 8;
        }
        .mixes-item-share {
          text-transform: uppercase;
          flex: 1;
          font-size: 0.12rem;
          border-left: none;
          border-right: none;
          border-radius: 0;
        }
        .mixes-item-delete {
          border-left: 0.02rem solid #fff;
          max-width: 0.32rem;
          border-radius: 0 0.04rem 0.04rem 0;
        }
      }
      .mixes-item-operate {
        background-color: #1f1e21;
        margin: 0;
        display: flex;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        button {
          flex: 1;
          background-color: rgba(0, 0, 0, 0.3);
          &:hover {
            background-color: #fff;
            color: #1f1e21;
          }
        }
        .mixes-item-cancel {
          border-right: none;
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
        }
        .mixes-item-confirm {
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
        }
      }
    }
  }

  .setting-footer {
    width: 100%;
    height: 0.95rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 0.16rem;
  }
}
</style>