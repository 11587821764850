import axios from 'axios';
import { get_unique_id } from './helper';
const BASE_URL = 'https://naviapi.needmust.cn';

export const http = axios.create({
  baseURL: BASE_URL,
  timeout: 5000
});

const Headers = {
  'front-url': document.referrer,
  dofrom: '',
  unique_id: get_unique_id()
};

// 请求拦截
http.interceptors.request.use(
  config => {
    config.headers = Headers;

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);
// 响应拦截
http.interceptors.response.use(
  async function (response) {
    return response.data;
  },
  async function (error) {
    return Promise.reject(error);
  }
);
