<template>
  <div class="content-layout">
    <div class="setting-layout">
      <div class="timeout-layout">
        <div class="setting-button" @click="allShow('time')">
          <span
            v-if="tab === '计时停止播放' && timestr && setType !== 'time'"
            >{{ timestr }}</span
          >
          <span v-else>计时器</span>
        </div>
      </div>
      <div class="reverberation-layout" @click="allShow('mixes')">
        <div class="setting-button">组合</div>
      </div>
      <div class="share-layout" @click="allShow('share')">
        <div class="setting-button">分享</div>
      </div>
    </div>
    <div
      :class="[
        'setting-main',
        setType === 'time' ? 'settingTimeout' : '',
        setType === 'mixes' ? 'settingMixes' : '',
        setType === 'share' ? 'settingShare' : '',
      ]"
    >
      <SetTimeout
        v-show="setType === 'time'"
        @close="allClose"
        @change="timeNumChange"
      />
      <SetMixes
        v-show="setType === 'mixes'"
        @mixesClose="allClose"
        @saveMixes="saveMixes"
        @mixesPlay="mixesPlay"
        @randomMixes="setRandomVolumes"
        @mixesShare="mixesShare"
        :list="mixesList"
      />
      <SetShare v-show="setType === 'share'" :currentSound="currentSound" />
    </div>
    <div class="play-layout">
      <div
        class="play-button"
        :class="{ stop: allPlay, play: !allPlay }"
        @click="allPlayChange"
      >
        <!-- <img src="~@/assets/images/play.png" alt="" /> -->
        <div class="isplay"></div>
      </div>
      <div class="play-info-layout">
        <div class="play-name">轻轻的呜咽</div>
        <div class="play-tip">周围的声音可以冲走注意力</div>
      </div>
    </div>
    <div class="music-list-layout">
      <ul class="music-list">
        <li
          class="music-item"
          v-for="(i, x) in musicList"
          :key="x"
          @click="volumeAdd(i)"
        >
          <img
            :style="`background-color: rgba(200,200,200, ${i.volume / 100})`"
            :src="i.icon"
            alt=""
          />
          <span>{{ i.name }}</span>
          <div class="music-slider" @click.stop>
            <WhiteSlider :value="i.volume" @input="inputChange(i, $event)" />
          </div>
          <audio :ref="i.name" class="music-audio" loop :src="i.audio"></audio>
        </li>
      </ul>
      <div class="music-more">
        <div class="more-button">更多声音</div>
      </div>
    </div>
  </div>
</template>

<script>
import WhiteSlider from '@/components/white-slider/white-slider.vue'
import { GetAudioList } from '@/api/index'
import SetTimeout from '../components/set-timeout.vue'
import SetMixes from '../components/set-mixes.vue'
import { audioObjectToString, audioStringToObject } from '@/utils/helper'
import SetShare from '../components/set-share.vue'

export default {
  components: { WhiteSlider, SetTimeout, SetMixes, SetShare },
  name: 'HomeContent',
  data() {
    return {
      musicList: [],
      allPlay: false,
      globalVolume: 1,
      timer: null,
      time: 0,
      timeShow: false,
      timestr: '',
      tab: '',
      mixesList: [],
      setType: '', // time, mixes, share
      currentSound: ''
    }
  },
  watch: {
    '$store.state.volume': {
      handler(newValue) {
        this.globalVolume = newValue / 100
        this.musicList.forEach(item => {
          this.inputChange(item, item.volume)
        })
      },
      deep: true
    }
  },
  created() {
    this.fetchAudioList()
    const saveList = window.localStorage.getItem('mixesList')
    if (saveList) {
      this.mixesList = JSON.parse(saveList)
    }
  },
  mounted() {
    const query = this.$route?.query
    if (query && query.m) {
      this.allPlay = true
      setTimeout(() => {
        this.mixesPlay({ set: query.m })
      }, 2000);
    }
  },
  methods: {
    allClose() {
      this.setType = ''
    },
    allShow(type) {
      this.setType = this.setType === type ? '' : type
      if (type === 'share') {
        this.currentSound = audioObjectToString(this.musicList)
      }
    },
    mixesShare(item) {
      if (item && item.set) {
        this.setType = 'share'
        this.currentSound = item.set
      }
    },
    mixesPlay(item) {
      if (item && item.set) {
        const newlist = audioStringToObject(item.set)
        const list = this.updateVolumes(this.musicList, newlist)
        this.musicList = list
        this.$nextTick(() => {
          this.allPlayChange('play')
        })
      }
    },
    setRandomVolumes() {
      // 随机选择2到8个音频
      const count = Math.floor(Math.random() * 7) + 2;
      const selectedAudios = [];

      // 生成不重复的随机索引
      while (selectedAudios.length < count) {
        const index = Math.floor(Math.random() * this.musicList.length);
        if (!selectedAudios.includes(index)) {
          selectedAudios.push(index);
        }
      }

      // 设置所有音频音量为0
      for (let i = 0; i < this.musicList.length; i++) {
        this.musicList[i].volume = 0;
      }

      // 设置选中音频的音量
      selectedAudios.forEach(index => {
        const volume = Math.floor(Math.random() * 99) + 1; // 1到100之间的随机音量
        this.musicList[index].volume = volume;
      });
      this.$nextTick(() => {
        this.allPlayChange('play')
      })
    },
    /**
     * 根据分享数据的音量来替换原有数据中的音量
     * 
     * @param {Array} originalData - 原始数据数组
     * @param {Array} shareData - 分享数据数组
     * @returns {Array} - 替换后的数据数组
     */
    updateVolumes(originalData, shareData) {
      // 创建一个映射表，用于快速查找分享数据中的音量
      const shareVolumeMap = new Map();
      shareData.forEach(item => {
        shareVolumeMap.set(item.id, item.volume);
      });

      // 遍历原始数据并更新音量
      return originalData.map(item => {
        const newVolume = shareVolumeMap.has(item.id) ? shareVolumeMap.get(item.id) : 0;
        return { ...item, volume: newVolume };
      });
    },
    saveMixes(name) {
      const saveList = this.musicList.filter(i => (i.volume && i.volume > 0)).map(item => ({ id: item.id, volume: item.volume }))
      const str = audioObjectToString(saveList)
      const saveData = {
        name,
        set: str
      }
      this.mixesList.unshift(saveData)
      window.localStorage.setItem('mixesList', JSON.stringify(this.mixesList))
    },
    updateAudioPlayback(time, tab) {
      if (tab === '计时停止播放') {
        if (time === '00:00:00') {
          if (this.allPlay) {
            this.allPlayChange();
          }
        } else {
          if (!this.allPlay) {
            this.allPlayChange();
          }
        }
      } else if (tab === '计时开始播放') {
        if (time === '00:00:00') {
          if (!this.allPlay) {
            this.allPlayChange();
          }
        } else {
          if (this.allPlay) {
            this.allPlayChange();
          }
        }
      }
    },
    timeNumChange(val) {
      if (val) {
        const { time, tab } = val
        this.timestr = time === '00:00:00' ? '' : time
        this.tab = tab
        this.updateAudioPlayback(time, tab)
      }
    },
    itemVolumeChange(audio, volume) {
      if (volume <= 0 || this.globalVolume <= 0) {
        audio.volume = 0
      } else {
        audio.volume = parseFloat((volume / 100 * this.globalVolume).toFixed(2))
      }
    },
    async fetchAudioList() {
      try {
        const res = await GetAudioList()
        if (res.code === 200 && res.data?.list?.length > 0) {
          this.musicList = res.data.list.map((i, x) => {
            return {
              ...i,
              volume: x === 0 ? 60 : 0,
              isplay: false,
              icon: i.avatarUrl,
              audio: i.soundUrl
            }
          })
        }
      } catch (error) {
        console.error('获取音频列表失败:', error)
      }
    },
    volumeAdd(i) {
      const audio = this.$refs[i.name][0]
      if (i.volume === 100) {
        i.volume = 0
      } else {
        i.volume += 20
        if (i.volume > 100) {
          i.volume = 100
        }
      }
      // audio.volume = i.volume / 100
      this.itemVolumeChange(audio, i.volume)
    },
    allPlayChange(play) {
      this.allPlay = play === 'play' ? true : !this.allPlay
      // 缓存音乐列表和对应的音频元素
      const musicList = this.musicList;
      const audioRefs = this.$refs;

      musicList.forEach((item) => {
        item.isplay = this.allPlay;

        // 获取音频元素
        const audioElement = audioRefs[item.name]?.[0];
        if (!audioElement) return; // 如果音频元素不存在，则跳过

        try {
          // audioElement.volume = item.volume / 100;
          this.itemVolumeChange(audioElement, item.volume)
          if (this.allPlay) {
            audioElement.play();
          } else {
            audioElement.pause();
          }
        } catch (error) {
          console.error('Failed to play/pause audio:', error);
        }
      });
    },
    playMusic(item) {
      const audio = this.$refs[item.name][0]
      if (audio) {
        // const volume = item.volume / 100
        // audio.volume = volume
        this.itemVolumeChange(audio, item.volume)
        if (audio.paused) {
          audio.play()
          item.isplay = true
        } else {
          audio.pause()
          item.isplay = false
        }
      }
    },
    inputChange(item, value) {
      item.volume = value
      if (this.setType === 'share') {
        this.currentSound = audioObjectToString(this.musicList)
      }
      const audio = this.$refs[item.name][0]
      if (audio) {
        // const volume = item.volume / 100
        // audio.volume = volume
        this.itemVolumeChange(audio, item.volume)
      }
    },
  }
}
</script>

<style lang='less' scoped>
.content-layout {
  width: 100%;
  min-height: 101vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  .setting-layout {
    height: 0.32rem;
    display: flex;
    margin-bottom: 0.3rem;
    .setting-button {
      width: 1.58rem;
      height: 0.32rem;
      border-radius: 0.04rem;
      border: 1px solid rgba(255, 255, 255, 0.19);
      display: flex;
      align-items: center;
      justify-content: center;
      color: #ffffff;
      font-size: 0.14rem;
      cursor: pointer;
      &:hover {
        background-color: rgba(255, 255, 255, 0.1);
      }
    }
    .reverberation-layout .setting-button {
      margin: 0 0.56rem;
    }
  }
  .play-layout {
    display: flex;
    width: 5.86rem;
    height: 1.1rem;
    align-items: center;
    margin-bottom: 0.8rem;
    margin-top: 0.42rem;
    .play-button {
      width: 0.8rem;
      height: 0.8rem;
      margin-right: 0.6rem;
      cursor: pointer;
      // img {
      //   width: 100%;
      // }
      .isplay {
        transition: border 0.15s linear, opacity 0.1s;
      }
    }
    .stop .isplay {
      border: 0 solid transparent;
      border-left: 0.256rem solid #fff;
      border-right: 0.256rem solid #fff;
      width: 0.72rem;
      height: 0.72rem;
    }
    .play .isplay {
      border: 0.4rem solid transparent;
      border-left: 0.72rem solid #fff;
      border-right: 0 solid #fff;
      width: 0.8rem;
      height: 0.8rem;
    }
    .play-info-layout {
      height: 1.1rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      color: #ffffff;
      .play-name {
        font-size: 0.48rem;
        font-weight: bold;
        margin-bottom: 0.1rem;
      }
      .play-tip {
        font-size: 0.16rem;
        text-align: center;
      }
    }
  }
  .music-list-layout {
    max-width: 8.7rem;
    width: 100%;
    margin: 0 auto;
    .music-list {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      font-size: 0.16rem;
      .music-item {
        width: 1.5rem;
        height: 1.5rem;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 0.2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin: 0 0.12rem 0.3rem;
        cursor: pointer;
        img {
          width: 0.55rem;
          height: 0.55rem;
          object-fit: cover;
          border-radius: 50%;
        }
        > span {
          margin-top: 0.1rem;
        }
        .music-slider {
          display: flex;
          justify-content: center;
          width: 100%;
          margin-top: 0.15rem;
          cursor: default;
          .white-slider {
            width: 0.8rem;
          }
        }
        .music-audio {
          display: none;
        }
      }
    }

    .music-more {
      text-align: center;
      margin: 0.2rem 0;
    }
  }

  .setting-main {
    width: 5.86rem;
    height: 0;
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 0.04rem;
    transition: height 0.3s;
    margin-top: 0.2rem;
  }
  .settingTimeout {
    height: 2.85rem;
  }
  .settingMixes {
    height: 3.8rem;
  }
  .settingShare {
    height: 2rem;
  }
}
</style>