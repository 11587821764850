<template>
  <div class="home">
    <div class="home-top">
      <HomeHeader />
    </div>
    <div class="home-content">
      <HomeContent />
    </div>
  </div>
</template>

<script>
import HomeContent from './home-content/home-content.vue'
import HomeHeader from './home-header/home-header.vue'
export default {
  components: { HomeHeader, HomeContent },
  name: 'HomePage',
  
}
</script>

<style lang='less' scoped>
.home {
  .home-content {
    margin-top: 1.2rem;
  }
}
</style>