<template>
  <div id="app">
    <router-view />
    <div v-if="loginshow">
      <PopupsLogin />
    </div>
    <div v-if="bindshow">
      <OtherLogin class="login_btn" type="wx" />
    </div>
  </div>
</template>

<script>
import OtherLogin from '@/components/other-login/Other-login.vue'
import PopupsLogin from '@/components/popups-login/popups-login.vue'

import { SET_TOKEN, SET_USER_INFO, GET_USER_INFO as GET_STORAGE_UINFO } from '@/utils/storage'
export default {
  name: 'AppPage',
  components: { OtherLogin, PopupsLogin },
  data() {
    return {
      userInfo: {},
      bindshow: false,
      loginshow: false
    }
  },
  watch: {
    '$store.state.loginShow': {
      handler(v) {
        this.loginshow = v
      }
    },
    $route: {
      handler(v) {
        this.$nextTick(() => {
          if (this.$route.path.indexOf('agreement') === -1) {
            this.bindshow = true
          }
        })
      },
      deep: true
    }
  },
  created() {
    if (location.search.indexOf('code=') > -1) {
      this.bindshow = true
    }
    if (!this.userInfo?.id && GET_STORAGE_UINFO()?.id) {
      this.userInfo = GET_STORAGE_UINFO()
      SET_TOKEN(this.userInfo?.token)
      SET_USER_INFO(JSON.stringify(this.userInfo))
      this.$store.commit('setUserInfo', this.userInfo)
    }
  },
}
</script>

<style lang="less">
#app {
  width: 100%;
  height: 100vh;
  background-color: rgba(5, 10, 13, 0.8);
  background-image: url("~@/assets/images/bg1.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  color: #fff;
  overflow: hidden;
}

nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
